<template>

  <div>
    <div>
      <b-row
        class="content-header vendor-reg-form"
      >
        <b-col
          class="content-header-left mb-2"
          cols="8"
          md="6"
        >
          <h1 class="custom-header-title">
            Add Vendor Payment
          </h1>
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="vendorPaymentCreateForm"
          #default="{invalid}"
        >
          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>step 1</small>
                Invoice Details
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <b-form-group
                  label="Invoice ID*"
                  label-for="h-booking-invoiceID"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Invoice ID"
                    vid="invoiceID"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-invoiceID"
                      v-model="invoiceID"
                      :state="(errors.length > 0 || invoiceIDValidation) ? false : null"
                      name="invoiceID"
                      placeholder="Enter an Invoice ID"
                      @input="invoiceIDValidation == true ? invoiceIDValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="invoiceIDValidation"
                      class="text-danger"
                    >
                      {{ invoiceIDError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Vendor Name*"
                  label-for="h-booking-vendorName"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Vendor Name"
                    vid="vendorName"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-vendorName"
                      v-model="vendorName"
                      :state="(errors.length > 0 || vendorNameValidation) ? false : null"
                      name="vendorName"
                      placeholder="Enter vendor name"
                      @input="vendorNameValidation == true ? vendorNameValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="vendorNameValidation"
                      class="text-danger"
                    >
                      {{ vendorNameError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Payment Method"
                  vid="paymentMethod"
                  rules=""
                >
                  <b-form-group
                    label="Payment Method"
                    label-for="h-booking-paymentMethod"
                    label-cols-md="4"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <v-select
                      id="h-bookings-paymentMethod"
                      v-model="paymentMethod"
                      label="title"
                      placeholder="Select from list"
                      :options="paymentMethodOptions"
                      :reduce="name => name.code"
                      :clearable="false"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Budget Code"
                  vid="budgetCode"
                  rules=""
                >
                  <b-form-group
                    label="Budget Code"
                    label-for="h-booking-budgetCode"
                    label-cols-md="4"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <v-select
                      v-if="budgetCodeOptions.length"
                      id="h-bookings-budgetCode"
                      v-model="budgetCode"
                      placeholder="Select from list"
                      :options="budgetCodeOptions"
                      :reduce="name => name._id"
                      :get-option-label="budget => budget.expenseCategory.name"
                      :clearable="false"
                    >
                      <template #option="{ expenseCategory }">
                        <span class="">{{ expenseCategory.code ? `${expenseCategory.code} - ` : '' }}{{ expenseCategory.name }}</span>
                      </template>

                      <template #selected-option="{ expenseCategory }">
                        <span class="">{{ expenseCategory ? `${expenseCategory.code} - ` : '' }}{{ expenseCategory.name }}</span>
                      </template>
                    </v-select>
                    <v-select
                      v-else
                      id="h-bookings-budgetCode"
                      v-model="budgetCode"
                      placeholder="No budget allocated yet"
                      :options="budgetCodeOptions"
                      :reduce="name => name._id"
                      :get-option-label="budget => budget.expenseCategory.name"
                      :clearable="false"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <b-form-group
                  label="Sub Total*"
                  label-for="h-booking-subTotal"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Sub Total"
                    vid="subTotal"
                    rules="required|min_value:0"
                  >
                    <b-form-input
                      id="h-booking-subTotal"
                      v-model="subTotal"
                      placeholder="$"
                      :state="(errors.length > 0 || subTotalValidation) ? false : null"
                      name="subTotal"
                      @input="subTotalValidation == true ? subTotalValidation = false : null; calculateTotalPayable()"
                      @keypress="validateNumberInput"
                      @paste="validateNumberPaste"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="subTotalValidation"
                      class="text-danger"
                    >
                      {{ subTotalError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="GST*"
                  label-for="h-booking-gstAmount"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="GST"
                    vid="gstAmount"
                    rules="required|min_value:0"
                  >
                    <b-form-input
                      id="h-booking-gstAmount"
                      v-model="gstAmount"
                      :state="(errors.length > 0 || gstAmountValidation) ? false : null"
                      name="gstAmount"
                      placeholder="$"
                      @input="gstAmountValidation == true ? gstAmountValidation = false : null; calculateTotalPayable()"
                      @keypress="validateNumberInput"
                      @paste="validateNumberPaste"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="gstAmountValidation"
                      class="text-danger"
                    >
                      {{ gstAmountError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Total Payable*"
                  label-for="h-booking-totalPayable"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Total Payable"
                    vid="totalPayable"
                    rules="required|min_value:0"
                  >
                    <b-form-input
                      id="h-booking-totalPayable"
                      v-model="totalPayable"
                      :state="(errors.length > 0 || totalPayableValidation) ? false : null"
                      name="totalPayable"
                      placeholder="$"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="totalPayableValidation"
                      class="text-danger"
                    >
                      {{ totalPayableError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>step 2</small>
                Attachment (Invoice / Reason form)
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
              >
                <vue-dropzone
                  id="event-images"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  :use-custom-slot="true"
                  @vdropzone-files-added="processFile"
                  @vdropzone-removed-file="fileRemoved"
                  @vdropzone-error="handleError"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drop files here or click to upload
                    </h3>
                    <div class="subtitle">
                      File should not bigger than 20 mb. JPEG, PNG, or PDF only
                    </div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mt-2"
                    >
                      Upload file
                    </b-button>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>step 3</small>
                Uploader's Remarks
              </h3>
            </template>
            <b-row>
              <b-col cols="md-12">
                <b-form-group
                  label="Remarks"
                  label-for="remarks"
                  label-cols-md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Remarks"
                    vid="remarks"
                  >
                    <b-form-textarea
                      id="remarks"
                      v-model="remarks"
                      rows="4"
                      placeholder="Optional"
                      :state="(errors.length > 0) ? false : null"
                      name="remarks"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>step 4</small>
                Approval and Verification
              </h3>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :href="loaFile"
                target="_blank"
              >
                <feather-icon
                  icon="InfoIcon"
                  class="mr-75"
                  size="20"
                />
                <span class="align-middle">View LOA</span>
              </b-button>
            </template>
            <b-row class="no_Signatories">
              <b-col cols="12">
                <div class="info_item">
                  <feather-icon
                    icon="InfoIcon"
                    class=""
                    size="20"
                  />
                  <span class="align-middle">For this invoice to be processed by Finance Dept., it has to follow approval flow by requester department and verified by a member of the CP team.
                    Please confirm the names of signatories required to sign on this form.</span>
                </div>
              </b-col>
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Number of Signatory Required"
                  vid="signatoryOption"
                  rules="required"
                >
                  <b-form-group
                    label="Number of Signatory Required*"
                    label-for="h-activity-type-signatoryOption"
                    label-cols-md="5"
                    :state="(errors.length > 0 || signatoryOptionValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-signatoryOption"
                      v-model="signatoryOption"
                      label="title"
                      :options="signatoryOptions"
                      :reduce="title => title.code"
                      :clearable="false"
                      @input="changeSignatory()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="signatoryOptionValidation"
                      class="text-danger"
                    >
                      {{ signatoryOptionError }}
                    </small>
                    <small
                      v-if="totalPayable >= 25000 && signatoryOption < 5"
                      class="text-danger"
                    >
                      Payment amount exceeded $25,000, 5 signatures are required
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="md-6" />
            </b-row>

            <hr class="dividerHR">
            <div v-if="signatoryOption">
              <b-row
                v-for="(opt, key) in loasComputation()"
                :key="key"
              >
                <b-col
                  v-if="signatoryOption > (key + 1)"
                  cols="md-6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=""
                    :vid="'event-custom-field-' + key"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-md="5"
                      :label="opt.title"
                      :label-for="'event-custom-field-' + key"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        id="h-bookings-dutyOfficer"
                        v-model="signatoryAssigns[key]"
                        label="name"
                        placeholder="Select an option"
                        :options="userOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="md-6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=""
                    vid="event-custom-field-n"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-md="5"
                      :label="signatoryOption + '. Checked By (CP)'"
                      label-for="event-custom-field-n"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        id="h-bookings-dutyOfficer"
                        v-model="signatoryAssigns[signatoryOption - 1]"
                        label="name"
                        placeholder="Select an option"
                        :options="userOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-card>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'purchasing-purchase-request-show', params: { id: $route.params.id }, query: { tab: 'invoice' } }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                :disabled="invalid"
                @click="finalConfirmation()"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Add Vendor Payment</span>
              </b-button>
            </li>
          </b-nav>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    vSelect,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      canViewThisAction,
      vendorPreview: false,
      user: store.state.auth.userData,
      invoiceID: '',
      vendorName: '',
      paymentMethod: '',
      budgetCode: '',
      subTotal: '',
      remarks: '',
      gstAmount: '',
      totalPayable: '',
      signatoryOption: 4,
      signatoryOptionOld: 4,
      attachments: [],
      invoiceIDError: 'Valid Invoice ID is required',
      invoiceIDValidation: false,
      subTotalError: 'Valid Tel is required',
      subTotalValidation: false,
      vendorNameError: 'Valid Vendor Name is required',
      vendorNameValidation: false,
      gstAmountError: 'Valid Fax is required',
      gstAmountValidation: false,
      paymentMethodError: 'Valid Payment Method is required',
      paymentMethodValidation: false,
      totalPayableError: 'Valid Registered Address is required',
      totalPayableValidation: false,
      budgetCodeError: 'Budget Code is required',
      budgetCodeValidation: false,
      signatoryOptionError: 'Valid Number of Signatory is required',
      signatoryOptionValidation: false,
      userOptions: [],
      signatoryAssigns: [],
      signatoryAssignsError: 'Valid Signatory is required',
      signatoryAssignsValidation: false,
      paymentMethodOptions: [
        { title: 'Cheque', code: 'Cheque' },
        { title: 'Bank Transfer (Direct Debit)', code: 'Bank Transfer (Direct Debit)' },
        { title: 'GIRO (Monthly Auto Deduction)', code: 'GIRO (Monthly Auto Deduction)' },
        { title: 'Petty Cash', code: 'Petty Cash' },
        { title: 'PayNow', code: 'PayNow' },
        { title: 'Telegraph Transfer (Overseas)', code: 'Telegraph Transfer (Overseas)' },
      ],
      budgetCodeOptions: [],
      signatoryOptions: [
        { title: '2', code: '2' },
        { title: '3', code: '3' },
        { title: '4', code: '4' },
        { title: '5', code: '5' },
      ],
      loas: [
        { title: '1. Approved By', code: 'Approved By' },
        { title: '2. Checked By', code: 'Checked By' },
        { title: '3. Verified By', code: 'Verified By' },
        { title: '4. Reviewed By (HQ)', code: 'Reviewed By (HQ)' },
      ],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      purchaseRequest: {},
      // eslint-disable-next-line global-require
      loaFile: `${process.env.VUE_APP_API_URL.slice(0, -4)}files/LOA-Singapore-Aug2022(Final).pdf`,

      // validation rules
      required,
    }
  },

  created() {
    this.$http.get(`purchase/vendor-payment/pr/${this.$route.params.id}/detail/with-extra`)
      .then(response => {
        this.userOptions = response.data.userOptions ?? []
        this.purchaseRequest = response.data.data || {}
        this.budgetExpense = response.data.budgetExpense
        this.expenseItems = response.data.expenseItems
        this.budgetCodeOptions = response.data.expenseItems
        this.signatoryAssigns.push(this.user._id)
        this.signatoryAssigns.push('')
        this.signatoryAssigns.push('')
        this.signatoryAssigns.push(this.purchaseRequest.assignedTo)
        if (this.budgetCodeOptions.length === 1) {
          this.budgetCode = this.budgetCodeOptions[0]._id
        }
        this.vendorName = this.purchaseRequest.vendor.companyName
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  // beforeRouteLeave(to, from, next) {
  //   const bcLength = store.state.breadcrumb.breadcrumbs.length
  //   if (bcLength > 2) {
  //     store.commit('breadcrumb/REMOVE_BREADCRUMB')
  //   }
  //   next()
  // },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    loasComputation() {
      if (this.signatoryOption === 5) {
        return [
          { title: '1. Checked By', code: 'Checked By' },
          { title: '2. Verified By', code: 'Verified By' },
          { title: '3. Approved By', code: 'Approved By' },
          { title: '4. Reviewed By (HQ)', code: 'Reviewed By (HQ)' },
        ]
      }

      if (this.signatoryOption === 2) {
        return [
          { title: '1. Approved By', code: 'Approved By' },
        ]
      }

      if (this.signatoryOption === 4) {
        return [
          { title: '1. Checked By', code: 'Checked By' },
          { title: '2. Verified By', code: 'Verified By' },
          { title: '3. Approved By', code: 'Approved By' },
        ]
      }

      if (this.signatoryOption === 3) {
        return [
          { title: '1. Checked By', code: 'Checked By' },
          { title: '2. Approved By', code: 'Approved By' },
        ]
      }

      return []
    },
    calculateTotalPayable() {
      this.totalPayable = (this.gstAmount || 0) - (-this.subTotal || 0)
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    finalConfirmation() {
      this.$swal({
        title: 'Submit this Vendor Payment Form?',
        html: 'You are proceeding to submit this Vendor Payment Form.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 20 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async changeSignatory() {
      // eslint-disable-next-line radix
      this.signatoryOption = parseInt(this.signatoryOption)
      if (this.signatoryOptionOld > this.signatoryOption) {
        await this.$nextTick()
        this.$swal({
          title: 'Are You Sure?',
          html: 'You are changing the number of signatory.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure.",
          customClass: {
            confirmButton: 'btn btn-danger ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.signatoryOptionOld = this.signatoryOption
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            } else {
              this.signatoryOption = this.signatoryOptionOld
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            }
          })
      } else {
        this.signatoryOptionOld = this.signatoryOption
        // eslint-disable-next-line radix
        const intOption = parseInt(this.signatoryOption)
        if (this.signatoryAssigns.length > intOption) {
          this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
        }
      }

      // this.signatoryAssigns.push(this.purchaseRequest.assignedTo)
      this.signatoryAssigns[this.signatoryOption - 1] = this.purchaseRequest.assignedTo
    },
    submitForm() {
      this.$refs.vendorPaymentCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('url', window.location.origin)
          formData.append('invoiceID', this.invoiceID)
          formData.append('purchaseRequest', this.purchaseRequest._id)
          formData.append('prID', this.purchaseRequest.prID)
          formData.append('prCaseID', this.purchaseRequest.poCaseID)
          formData.append('cpUser', this.purchaseRequest.assignedTo)
          formData.append('vendor', this.purchaseRequest.vendor._id)
          formData.append('subTotal', this.subTotal)
          formData.append('vendorName', this.vendorName)
          formData.append('gst', this.gstAmount)
          formData.append('paymentMethod', this.paymentMethod)
          formData.append('totalPayable', this.totalPayable)
          formData.append('uploaderRemarks', this.remarks)
          formData.append('budgetCode', this.budgetCode)
          formData.append('signatoryOption', this.signatoryOption)
          formData.append('attachments', JSON.stringify(this.attachments))
          formData.append('signatoryAssigns', JSON.stringify(this.signatoryAssigns))
          formData.append('loas', JSON.stringify(this.loasComputation()))

          this.$http.post('purchase/vendor-payment/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Invoice Created',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'View Entry',
                  customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id }, query: { tab: 'invoice' } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'invoiceID') {
                    this.invoiceIDError = validationError.msg
                    this.invoiceIDValidation = true
                  } else if (validationError.param === 'subTotal') {
                    this.subTotalError = validationError.msg
                    this.subTotalValidation = true
                  } else if (validationError.param === 'vendorName') {
                    this.vendorNameError = validationError.msg
                    this.vendorNameValidation = true
                  } else if (validationError.param === 'gst') {
                    this.gstAmountError = validationError.msg
                    this.gstAmountValidation = true
                  } else if (validationError.param === 'paymentMethod') {
                    this.paymentMethodError = validationError.msg
                    this.paymentMethodValidation = true
                  } else if (validationError.param === 'totalPayable') {
                    this.totalPayableError = validationError.msg
                    this.totalPayableValidation = true
                  } else if (validationError.param === 'paymentMethod') {
                    this.paymentMethodError = validationError.msg
                    this.paymentMethodValidation = true
                  } else if (validationError.param === 'signatoryOption') {
                    this.signatoryOptionError = validationError.msg
                    this.signatoryOptionValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

    <style scoped>
      .removeText {
        color:#D91B35;
        cursor: pointer;
      }
      .addOptionText {
        color: #104D9D;
        cursor: pointer;
      }
      .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
        border-right-width: 1px !important;
      }
      .inlineElement {
        display: flex;
      }
      .step-class {
        color: #104D9D;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
      }
      .dropzone-custom-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
      .dropzone-custom-title {
        margin-top: 0;
        color: #104D9D;
      }
      .subtitle {
        color: #333333;
      }
      .bookingCounter {
        border-radius: 50%;
        width: 5px;
        height: 5px;
        padding: 2px;

        background: #fff;
        border: 1px solid #666;
        color: #666;
        text-align: center;

        font-size: 10px;
      }

      .vs__dropdown-option--disabled {
        background: #ededed;
        color: #000000;
      }
    </style>
